import React from 'react'
import { graphql } from 'gatsby'

import BlockContent from '@sanity/block-content-to-react'

import { Box, Image, Text, Flex, Link, Heading, Avatar } from 'theme-ui'

import SEO from '../components/seo'
import { toPlainText } from '../lib/helpers'

import {
  AvatarBox,
  // Avatar,
  Wrapper,
  Spacer,
  LinkBase,
  Name,
  Container,
} from '../components/atoms'

import PortableText from '../components/portableText'
import Layout from '../components/layout'

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      subtitle
      description
      keywords
    }
    blog: sanityPost(id: { eq: $id }) {
      id
      title
      _rawBody
      author {
        image {
          asset {
            fixed(width: 40, height: 40) {
              src
              width
              height
            }
          }
        }
        name
        role
      }
    }
  }
`

export const BlogTemplate = (props) => {
  const { data, errors } = props
  const post = data && data.blog
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && <SEO title={post.title || 'Untitled'} description="" />}
      <Container>
        <Spacer>
          {post && (
            <Box width={[1, 1, 7 / 12]} ml={6} mb={6}>
              <Text>Blog > {post.name}</Text>
              <Heading
                fontSize={[4, 5, 6]}
                mt={2}
                mb={2}
                fontWeight="bold"
              >
                {post.title}
              </Heading>
              <Box py={4}>
                <Flex>
                  <Avatar
                    src={post.author.image.asset.fixed.src}
                    width={post.author.image.asset.fixed.width}
                    height={post.author.image.asset.fixed.height}
                  />
                  <Text fontSize={3} ml={3} mt={2} fontWeight={600}>
                    {post.author.name}
                  </Text>
                  <Text ml={3} mt={2}>
                    Jan 24, 2020
                  </Text>
                </Flex>
              </Box>
              <Box pt={3}>
                <PortableText blocks={post._rawBody} />
              </Box>
            </Box>
          )}
        </Spacer>
      </Container>
    </Layout>
  )
}

export default BlogTemplate
